.wi-input-container.select:after,
.wi-input-container.text-list:after {
content: "\F282";
position: absolute;
right: 0;
padding: calc(20px + var(--input-border-bottom) + var(--input-border-top)) 16px;
display: inline-block;
font-family: bootstrap-icons !important;
font-style: normal;
font-variant: normal;
font-weight: 400 !important;
line-height: 1;
text-transform: none;
vertical-align: -.125em;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
transition: .3s;
pointer-events: none !important;
}
.wi-input-container.select.wi-list-active:after,
.wi-input-container.text-list.wi-list-active:after {
transform: scaleY(-1); 
-webkit-transform: scaleY(-1); 
-moz-transform: scaleY(-1); 
-ms-transform: scaleY(-1); 
-o-transform: scaleY(-1); 
}

.wi-input-container.wi-list-active  {
z-index: 10;
overflow: unset;
}
.wi-input-container.wi-list-active .wi-input {
border-radius: var(--input-border-radius) var(--input-border-radius) 0 0;
}
.wi-input-container .wi-input-list {
position: absolute;
float: left;
top: calc(56px + var(--input-border-top));
width: calc(100% - var(--input-border-bottom) - var(--input-border-bottom));
max-height: 0px;
color: var(--input-dropdown-tx-color);
background: var(--input-dropdown-bg-color);
border-top: 0px solid var(--input-border-focus);
border-left: var(--input-border-bottom) solid var(--input-border-focus);
border-right: var(--input-border-bottom) solid var(--input-border-focus);
border-bottom: 0px solid var(--input-border-focus);
border-radius: 0 0 var(--input-border-radius) var(--input-border-radius);
transition: .3s;
overflow: hidden;
z-index: 2;
}
.wi-input-container.wi-list-active .wi-input-list {
border-top: var(--input-border-bottom) solid var(--input-border-focus);
border-bottom: var(--input-border-bottom) solid var(--input-border-focus);
max-height: 120px;
overflow: scroll;
z-index: 2;
transition: .3s;
}
.wi-input-container.search-url.wi-list-active .wi-input-list {
max-height: 200px;
padding-bottom: 30px;
}
.wi-input-container .wi-input-list .wi-input-list-value,
.wi-input-container .wi-input-list .wi-input-list-footer {
width: calc(100% - 32px);
font-size: 16px;
height: auto;
max-height: auto;
line-height: 16px;
padding: 8px 16px;
cursor: pointer;
background: var(--input-dropdown-bg-color);
font-family: var(--input-tx-family);
transition: .3s;
}
.wi-input-container .wi-input-list .wi-input-list-footer {
position: absolute;
bottom: 0;
border-top: var(--input-border-bottom) solid var(--input-border-focus);
background: var(--input-select-hover);
font-size: 14px;
height: 14px;
line-height: 14px;
}
.wi-input-container .wi-input-list .wi-input-list-value:hover {
background: var(--input-select-hover);
}
.wi-input-container .wi-input-list .wi-input-list-value:last-child { 
border: none 
}
.wi-input-container .wi-input.select-selected {
cursor: pointer;
}
.wi-input-container .wi-input-list .same-as-selected {
font-weight: 500;
}