.pac-container {
font-family: var(--input-tx-family);
background: var(--input-dropdown-bg-color);
border-radius: 0 0 var(--input-border-radius) var(--input-border-radius);
border: var(--input-border-bottom) solid var(--input-border-focus);
margin-top: calc(0px - var(--input-border-bottom));
}
.pac-logo::after,
.pac-icon {
display: none;
}
.pac-item-query {
font-size: 14px;
}
.pac-item {
font-size: 14px;
line-height: 14px;
padding: 12px 16px;
cursor: pointer;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
text-align: left;
border-top: 1px solid #e6e6e6;
color: var(--input-dropdown-tx-color);
}